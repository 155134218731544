<template>
    <ion-header translucent>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button text="" default-href="/" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <blank-slate
        icon="offline"
        title="Офлайнаар ашиглах боломжгүй"
        description="Манай сайт тун удахгүй офлайн горим дэмждэг болно. ."
      />
    </ion-content>
  </template>