<script setup></script>

<template>
  <ion-page>
    <ion-content>
      <div class="flex flex-col justify-center items-center text-center h-full">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Зөвхөн бүртгүүлсэн хэрэглэгчдэд нээлттэй.</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <p class="indent-1">
             Та манай сайтад бүртгүүлснээр өөрийн дуртай, сурч буй болон сурахыг хүсэж буй дуугаа ангилан хадгалах боломжтой "Миний туслах цэс"-ийг ашиглах эрхтэй болно.
            </p>
          </ion-card-content>
        </ion-card>

        <div class="grid grid-cols-2 mt-8 gap-12 text-center">
          <div>
            <ion-button fill="outline" router-link="#signin">
              Нэвтрэх
            </ion-button>
          </div>
          <div>
            <ion-button router-link="#signup"> Бүртгүүлэх </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
